import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import StockExchangePage from "./StockExchangePage";
import { useParams } from "react-router";
import StaticPage from "./exchangeDirectoryPages";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import ThemeButton from "../common/button";
import StockList from "./StockList";
import Wallet from "./newStocks/Wallet";
import Spinner from "../common/Spinner/Spinner";
import { Transaction } from "@sentry/tracing";
import Portfolio from "./newStocks/PortfolioList";
// src\asset\images\stocks\Add_Account.svg

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: "4px solid #359DB6",
    backgroundColor: theme.palette.background.default,
    padding: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(950)]: {
      marginTop: "1rem",
      width: "100%",
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },

    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },

  button: {
    textTransform: "none",
    borderRadius: "6px",
    background: "#359Db6",
    marginTop: "2vh",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
    "&:disabled": {
      background: "#9ACEDB",
      color: "#919191",
    },
  },

  textBox: {
    border: "1px solid Grey",
    borderRadius: "5px",
    marginTop: "2vh",
    paddingLeft: "10px",
    textDecoration: "none",
  },
  mainGrid: {
    justifyContent: "Center",
    display: "flex",
    // flexDirection: "column",
    // paddingLeft:"9rem",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 32,
    //color: "#ffffff",
    display: "inline-block",
    borderBottom: "4px solid #FF7300",
    marginBottom: "1rem",
    [theme.breakpoints.down(500)]: {
      fontSize: 25,
    },
  },

  subHeaderText: {
    fontWeight: "600",
    fontSize: 22,
    color: "#C0671E",
    display: "inline-block",
    // borderBottom: "4px solid #FF7300",
    // marginBottom: "1rem",
    [theme.breakpoints.down(1000)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 16,
    },
  },
  subHeader2: {
    fontWeight: "600",
    fontSize: 25,
    display: "inline-block",
    marginBottom: "1rem",
    // diaplay: "flex",
    [theme.breakpoints.down(900)]: {
      fontSize: 20,
      marginBottom: "1rem",
    },
  },
  alignItemEnd: {
    display: "flex",
    alignItems: "end",
  },
  customTabRoot: {
    fontSize: "5rem",
    textTransform: "none",
  },
  customTabIndicator: {
    backgroundColor: "#FF7300",
  },
  tabText: {
    fontSize: "1.3rem",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
    //   color: "#fff",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stocksContainer: {
    // padding: "1rem ",
    // boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: "10px",
    display: "flex",

    // margin: "1%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "1.2rem 0",
    },
  },
  backContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0.3rem 0",
  },
  transactionsContainer: {
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    boxShadow: localStorage?.getItem("dark")
      ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
      : "none",
  },
}));

const AddAccount = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    profile,
    exchangeDirectory,
    getExchangeDirectory,
    getAllStocksEngine,
    getPortfolioEngine,
    allStockEngineList,
    stockEngineListLoading,
    getEngineWallet,
    walletDataLoading,
    walletData,
    getStockTxnEngine,
    getWalletTxnEngine,
    stockTransactionData,
    stockWalletTransactionData,
    transactionListLoading,
    walletTransactionListLoading,
    singleUserStockList,
    getStockCategory,
    stockCategory,
    stockCategoryLoading,
    getPrevInvestment,
    selectedStockCategory,
  } = props;
  // const [open, setOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();
  const params = useParams();
  const queryParams = new URLSearchParams(window.location.search);

  const [updatedParam, setUpdatedParam] = useState(undefined);
  const [exchangeDirectoryPage, setExchangeDirectoryPage] = useState(null);

  let tabs;
  if (profile?.status?.is_cogito_stocks_profile) {
    tabs = ["Stocks", "My Orders", "Portfolio", "Transactions", "Wallet"];
  } else {
    tabs = ["Stocks", "Portfolio"];
  }

  const a11yProps = (index) => ({
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  });

  const goBack = () => {
    history.push("/stocks");
    setUpdatedParam(undefined);
  };

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (updatedParam !== params.directory) {
      const tabQueryParam = queryParams.get("tab");
      if (tabQueryParam) {
        setUpdatedParam(params.directory);
        setSelectedTab(Number(tabQueryParam));
      }
    }
  }, [params.directory, updatedParam, queryParams]);

  useEffect(() => {
    getExchangeDirectory();
  }, [updatedParam]);

  useEffect(() => {
    if (exchangeDirectory?.length && updatedParam) {
      const matchedDirectory = exchangeDirectory.find(
        (data) => data.route === params.directory
      );
      if (matchedDirectory) {
        setExchangeDirectoryPage({ ...matchedDirectory, selectedTab });
      } else {
        setExchangeDirectoryPage(null);
      }
    }
  }, [exchangeDirectory, params.directory, selectedTab, updatedParam]);

  useEffect(() => {
    if (tabs.length === 5) {
      if (selectedTab === 0 || selectedTab === 1) {
        getAllStocksEngine({
          limit: 10,
          page: 0,
          category: selectedStockCategory,
        });
        getStockCategory({});
      } else if (selectedTab === 4) {
        getEngineWallet();
      } else if (selectedTab === 2) {
        getPortfolioEngine({
          limit: 10,
          page: 0,
        });
      }
    } else {
      if (selectedTab === 0) {
        getAllStocksEngine({
          limit: 10,
          page: 0,
          category: selectedStockCategory,
        });
        getStockCategory({});
      } else if (selectedTab === 1) {
        getPortfolioEngine({
          limit: 10,
          page: 0,
        });
      }
    }
  }, [selectedTab]);

  const renderedTabs = useMemo(
    () => (
      <Tabs
        value={selectedTab}
        onChange={(e, v) => handleTabChange(v)}
        variant="fullWidth"
        classes={{
          root: classes.customTabRoot,
          indicator: classes.customTabIndicator,
        }}
        aria-label="tabs"
      >
        {tabs.map((heading, index) => (
          <Tab
            key={index}
            label={<span className={classes.tabText}>{heading}</span>}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    ),
    [tabs, selectedTab, classes]
  );

  if (!tabs?.length) return null;

  return (
    <>
      <Grid container style={{ margin: "0" }}>
        {renderedTabs}
        {exchangeDirectoryPage &&
        selectedTab == exchangeDirectoryPage.selectedTab ? (
          <Grid item xs={12}>
            <Box className={classes.backContainer}>
              <ThemeButton
                title="Back"
                background={theme.palette.background.default}
                color="#359DB6"
                border="2px solid #359DB6"
                width="120px"
                onClick={goBack}
              />
            </Box>
          </Grid>
        ) : null}
        {exchangeDirectoryPage &&
        exchangeDirectoryPage?.selectedTab == selectedTab ? (
          <StaticPage exchangeDirectory={exchangeDirectoryPage} />
        ) : (
          <>
            <Grid item container xs={12} className={classes.stocksContainer}>
              {selectedTab === 0 && (
                <Grid item xs={12}>
                  {stockEngineListLoading ? (
                    <Spinner />
                  ) : (
                    <StockList tab="stockList" />
                  )}
                </Grid>
              )}

              {selectedTab === 1 && tabs.length === 5 && (
                <StockList tab="myOrders" handleTabChange={handleTabChange} />
              )}

              {((selectedTab === 2 && tabs.length === 5) ||
                (selectedTab === 1 && tabs.length === 2)) && (
                <Portfolio tab="portfolio" handleTabChange={handleTabChange} />
              )}

              {selectedTab === 4 && (
                <Grid item xs={12}>
                  {/* <StockExchangePage /> */}
                  <Wallet />
                </Grid>
              )}
            </Grid>

            {selectedTab === 3 && (
              <Grid item xs={12}>
                <StockList tab="transaction" />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    trader: userReducer.trader,
    exchangeDirectory: userReducer.exchangeDirectory,
    allStockEngineList: userReducer.allStockEngineList,
    stockEngineListLoading: userReducer.loadings.stockEngineList,
    walletData: userReducer.walletData,
    walletDataLoading: userReducer.loadings.walletDataLoading,
    stockTransactionData: userReducer.stockTransactionData,
    stockWalletTransactionData: userReducer.stockWalletTransactionData,
    transactionListLoading: userReducer.loadings.transactionList,
    walletTransactionListLoading: userReducer.loadings.walletTransactionList,
    singleUserStockList: userReducer.singleUserStock,
    stockCategory: userReducer.stockCategory,
    stockCategoryLoading: userReducer.stockCategoryLoading,
    selectedStockCategory: userReducer.selectedStockCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(actionCreator.getProfile()),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    registerStocksAccount: (data) =>
      dispatch(actionCreator.registerStocksAccount(data)),
    getExchangeDirectory: (request) =>
      dispatch(actionCreator.getExchangeDirectory(request)),
    getAllStocksEngine: (data) =>
      dispatch(actionCreator.getAllStocksEngine(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
    createStockAccount: () => dispatch(actionCreator.createStockAccount()),
    getEngineWallet: () => dispatch(actionCreator.getEngineWallet()),
    getStockTxnEngine: (data) =>
      dispatch(actionCreator.getStockTxnEngine(data)),
    getWalletTxnEngine: (data) =>
      dispatch(actionCreator.getWalletTxnEngine(data)),
    getStockCategory: (data) => dispatch(actionCreator.getStockCategory(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
