import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Link } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Nav from "../components/preloggedin/Nav";
import * as actionCreator from "../store/action/index";
import Sidebar from "../components/preloggedin/Sidebar";
import SmallSpinner from "../components/common/smallSpinner/smallSpinner";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";

import { updateObjectProperty, setWithExpiry } from "../asset/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: "#359Db6",
    },
    "& .MuiInputBase-root": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#359Db6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "#359Db6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#359Db6",
      },
      "& .MuiIconButton-root": {
        color: "#359Db6",
      },
    },
  },

  input: {
    color: "grey",
  },

  inputText: {
    color: "#000",
  },

  buttonContainer: {
    background: "red",
    width: isMobile ? "40vw" : "20vw",
    fontSize: 18,
    margin: "auto",
    height: 48,
    borderRadius: 8,
    color: "#fff",
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
  },

  forgotPassword: {
    cursor: "pointer",
    color: "#FF7300",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "#FF7300",
    },
  },
}));

const LoginForm = (props) => {
  const [user, setUser] = useState({
    id: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const history = useHistory();

  const paramRef = useParams().ref || null;

  const { loading, errorMessage, successMessage, LogInAsync, location } = props;

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef, setWithExpiry]);

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUser(updateObjectProperty(user, name, value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    LogInAsync(user, history, location?.state?.prevLocation);
  };

  const classes = useStyles();

  return (
    <Grid container style={{ height: "100vh", background: "#fff" }}>
      <Sidebar />
      <Grid style={{ color: "black" }} item xs={12} sm={12} md={8}>
        <Grid
          container
          align="center"
          direction="column"
          style={{ height: isMobile ? "85vh" : "100vh" }}
        >
          <Nav detailOne={"Don't have an account? "} detailTwo={"Sign Up"} />
          <Grid container align="center" justify="center" direction="column">
            <form
              method="post"
              onSubmit={handleSubmit}
              style={{
                height: isMobile ? "80vh" : "95vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "black",
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item xs={10} style={{ marginBottom: "2.5rem" }}>
                  <Typography
                    style={{
                      fontSize: "2rem",
                      textShadow: "0px 1px 2px #787A91",
                    }}
                  >
                    Welcome Back
                  </Typography>
                </Grid>
                <Grid item xs={10} style={{ marginBottom: "2.5rem" }}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="id"
                    label="Email or Username"
                    name="id"
                    value={user.id}
                    type="text"
                    // autoComplete="email"
                    autoFocus
                    className={classes.root}
                    InputLabelProps={{
                      className: classes.input,
                    }}
                    InputProps={classes.inputText}
                    onChange={onInputValueChanged}
                  />
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="password"
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={user.password}
                    onChange={onInputValueChanged}
                    // autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            // className={classes.root}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.root}
                    InputLabelProps={{
                      className: classes.input,
                    }}
                    inputProps={classes.inputText}
                  />
                  <Link href="/forgotpassword">
                    <Typography
                      align="right"
                      variant="caption"
                      component="h3"
                      color="primary"
                      className={classes.forgotPassword}
                      // onClick={removeRefferalCode}
                    >
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={10}>
                  {errorMessage.length ? (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        icon={false}
                        style={{ background: "#FCDDC4", color: "#FE7B10" }}
                      >
                        <strong>Error : {errorMessage}</strong>
                      </Alert>
                    </Grid>
                  ) : null}
                  {successMessage.length ? (
                    <Grid item xs={12}>
                      <Alert
                        severity="success"
                        icon={false}
                        style={{ backgroundColor: "#B7D2D9", color: "#12586A" }}
                      >
                        <strong>Success :{successMessage}</strong>
                      </Alert>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={10} style={{ marginTop: "2.5rem" }}>
                  <Button
                    className={classes.buttonContainer}
                    type="submit"
                    variant="contained"
                    id="loginButton"
                  >
                    {!loading ? "Submit" : <SmallSpinner />}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    error: authReducer.error,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    LogInAsync: (data, history, prevLocation) =>
      dispatch(actionCreator.LogInAsync(data, history, prevLocation)),
    ResetErrors: () => dispatch(actionCreator.ResetErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
