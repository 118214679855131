import React, { useEffect, useState } from "react";
import * as actionCreator from "../../store/action/index";
import { Link } from "react-router-dom";
import userReducer from "../../store/reducer/userReducer";
import { connect } from "react-redux";
import StockCard from "./newStocks/StockCard";
import StockTransactionList from "./newStocks/StockTransactionList";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Box,
  FormGroup,
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  Select,
  useTheme,
  FormControlLabel,
} from "@material-ui/core";
import Spinner from "../common/Spinner/Spinner";
import BuyStockModal from "./newStocks/BuyStockModal";
import PaginationComponent from "../pagination/PaginationComponent";
import PdfViewer from "../common/pdfViewer/index";
import StockAgreement from "../../asset/docs/stock_Exchange_Rules.pdf";
import PortfolioList from "./newStocks/PortfolioList";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    padding: theme.spacing(3, 9),
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  message: {
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    marginBottom: theme.spacing(2.5),
    fontSize: "18px",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#C49C39",
    color: "#fff",
    textAlign: "center",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(2.5),
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#b3862b",
    },
    "&:disabled": {
      backgroundColor: "#d1c3a7",
      color: "#999",
      cursor: "not-allowed",
      opacity: 0.7,
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "20%",
      marginRight: "20%",
    },
  },
  modalContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  formGroup: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  formControlLabel: {
    marginRight: 0,
  },
  link: {
    fontWeight: 700,
    textDecorationLine: "underline",
    color: "#C49C39",
    paddingLeft: 5,
    cursor: "pointer",
    marginLeft: 5,
    marginRight: 5,
  },
}));

const StockList = (props) => {
  const {
    profile,
    stockList,
    getStocks,
    userStocks,
    tab,
    userStocksTransations,
    userStockTransactionCount,
    // createStockAccount,
    createStockAccountEngine,
    stockAccount,
    handleTabChange,
    stocksOwned,
    stockListLoading,
    stockTransactionLoading,
    allStockEngineList,
    singleUserStockList,
    userStockLoading,
    singleUserStockCount,
    getAllStocksEngine,
    allStockDataCurrentPage,
    portfolioDataCurrentPage,
    getPortfolioEngine,
    getProfile,
    selectedStockCategory,
  } = props;

  const [userPortfolio, setUserPortfolio] = useState([]);
  const [stocks, setStock] = useState();
  const [createStock, setCreateStock] = useState(false);
  const [open, setOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [buyModalData, setBuyModalData] = useState(null);
  const [currentPortfolioPage, setCurrentPortfolioPage] = useState(
    portfolioDataCurrentPage
  );
  const [selectedStockId, setSelectedStockId] = useState(null);

  const classes = useStyles();

  const dataPerPage = 10;
  const totalPortfolioPage = Math.ceil(singleUserStockCount / dataPerPage);

  const handleStocks = ({ stockDetails, currencyType }) => {
    setOpen(!open);
    if (stockDetails) {
      setBuyModalData({ stockDetails, currencyType });
    } else {
      setBuyModalData(null);
    }
  };

  const handleBuyStocks = ({ stockDetails, currencyType, title }) => {
    setBuyOpen(!buyOpen);
    if (stockDetails) {
      setBuyModalData({ stockDetails, currencyType, title });
    } else {
      setBuyModalData(null);
    }
  };

  const handleCreateAccount = () => {
    createStockAccountEngine({
      acceptedEngineTerms: checked,
      callBack: handleTabChange,
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    setBuyOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOpen = () => {
    setTermsModalOpen(true);
  };

  const handleClose = () => {
    setTermsModalOpen(false);
  };

  const handleState = () => {
    setCreateStock(false);
  };

  const handlePaginationData = (val1, val2) => {
    getAllStocksEngine({
      limit: val1,
      page: val2,
      category: selectedStockCategory,
    });
  };

  const handlePaginationPortfolioData = (val) => {
    getPortfolioEngine({
      limit: dataPerPage,
      page: val,
    });
  };

  const handleSelectStock = (stockId) => {
    setSelectedStockId((prevId) => (prevId === stockId ? null : stockId));
  };

  return (
    <>
      {/* { : null} */}

      {tab == "portfolio" && !open ? (
        userStockLoading ? (
          <Spinner />
        ) : (
          <Grid container item xs={12} direction="column" spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <PortfolioList
                paginationPortfolioCB={handlePaginationPortfolioData}
                handleStocks={handleStocks}
              />
            </Grid>
            <Grid item xs={6}>
              {profile?.status?.is_cogito_stocks_profile &&
              Array.isArray(singleUserStockList) &&
              singleUserStockList.length > 0 ? (
                <Box
                  display="flex"
                  direction="row"
                  justifyContent="center"
                  style={{ marginTop: "1rem" }}
                >
                  <PaginationComponent
                    page={currentPortfolioPage + 1}
                    pageCount={totalPortfolioPage}
                    onChange={(e, v) => {
                      setCurrentPortfolioPage(v - 1);
                      handlePaginationPortfolioData(v - 1);
                    }}
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        )
      ) : tab == "transaction" ? (
        <>
          <StockTransactionList
            transactionData={userStocksTransations}
            userStockTransactionCount={userStockTransactionCount}
            stockList={stockList}
            stockTransactionLoading={stockTransactionLoading}
            userLoggedIn={profile}
          />
        </>
      ) : tab == "myOrders" ? (
        <>
          <StockTransactionList
            transactionData={userStocksTransations}
            userStockTransactionCount={userStockTransactionCount}
            stockList={stockList}
            stockTransactionLoading={stockTransactionLoading}
            userLoggedIn={profile}
            location={"MO"}
          />
        </>
      ) : stockListLoading ? (
        <Spinner />
      ) : open || buyOpen ? (
        <BuyStockModal
          open={open}
          buyOpen={buyOpen}
          stockDetails={buyModalData?.stockDetails}
          currType={buyModalData?.currencyType}
          createStock={createStock}
          handleClose={handleCloseModal}
          title={buyModalData?.title}
          handleState={handleState}
          minQuantity={buyModalData?.minQuantity}
          handleStocks={handleStocks}
          handleBuyStocks={handleBuyStocks}
          getPortfolioEngine={getPortfolioEngine}
          tab={tab}
        />
      ) : !open || !buyOpen ? (
        <>
          <StockCard
            stockList={allStockEngineList}
            userLoggedIn={profile}
            handleStocks={handleStocks}
            paginationCB={handlePaginationData}
            currPage={allStockDataCurrentPage}
          />
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    stockList: userReducer.stockList,
    allStocksEngineList: userReducer.allStocksEngineList,
    userStocks: userReducer.userStocks,
    userStocksTransations: userReducer.userStocksTransations,
    userStockTransactionCount: userReducer.userStockTransactionCount,
    stockAccount: userReducer.loadings.stockAccount,
    stocksOwned: userReducer.loadings.stocksOwned,
    stockListLoading: userReducer.loadings.stockList,
    stockTransactionLoading: userReducer.loadings.stockTransaction,
    allStockEngineList: userReducer.allStockEngineList,
    singleUserStockList: userReducer.singleUserStock,
    singleUserStockCount: userReducer.singleUserStockCount,
    userStockLoading: userReducer.loadings.userStock,
    allStockDataCurrentPage: userReducer.allStockDataCurrentPage,
    portfolioDataCurrentPage: userReducer.portfolioDataCurrentPage,
    selectedStockCategory: userReducer.selectedStockCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createStockAccountEngine: (data) => {
      dispatch(actionCreator.createStockAccountEngine(data));
    },
    getAllStocksEngine: (data) =>
      dispatch(actionCreator.getAllStocksEngine(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StockList);
