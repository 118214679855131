import React, { useEffect } from "react";
import thank_you_icon from "../../asset/images/SignIn/thank_you_icon.svg";
import { Button, Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    background: "red",
    fontSize: 18,
    margin: "auto",
    height: 48,
    borderRadius: 8,
    color: "#fff",
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
  },
}));

const redirectToApp = () => {
  let url = "https://play.google.com/store/apps/details?id=com.cogitoapp";
  window.open(url, "_blank");
};

const ThankYouPage = (props) => {
  const { successMsg, notify } = props;
  const classes = useStyles();

  useEffect(() => {
    if (successMsg === "Successfully registered") {
      let keysToRemove = ["regUser", "token"];
      keysToRemove.forEach((k) => localStorage.removeItem(k));
    }
  }, [successMsg]);

  useEffect(() => {
    if (successMsg === "Successfully registered") {
      notify(successMsg, "success");
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      style={{ height: isMobile ? "60vh" : "80vh" }}
    >
      <Grid item container xs={12} sm={8} justifyContent="center">
        <img
          src={thank_you_icon}
          alt="thank_you"
          width="250px"
          height="350x"
          style={{ objectFit: "contain" }}
        />
      </Grid>
      {!window.location.pathname.includes("staff") && (
        <Grid item container xs={8} sm={5} justifyContent="center">
          <Button
            fullWidth
            component={Link}
            to="/login"
            className={classes.buttonContainer}
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
        </Grid>
      )}
      {window.location.pathname.includes("staff") && (
        <Grid item container xs={8} sm={5} justifyContent="center">
          <Button
            fullWidth
            component={Link}
            onClick={redirectToApp}
            className={classes.buttonContainer}
            variant="contained"
          >
            Sign In on our App
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    status: userReducer.status,
    loadings: userReducer.loadings,
    accounts: userReducer.accounts,
    recommendations: userReducer.recommendations,
    successMsg: authReducer.successMessage,
    errorMsg: authReducer.errorMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
