import React from "react";
import { isMobile } from "react-device-detect";

import { Grid, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import CustomStepper from "../common/stepper/CustomStepper";
import Nav from "../preloggedin/Nav";
import LinkExpired from "../preloggedin/LinkExpired";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },

  buttonContainer: {
    width: "100%",
    fontSize: 18,
    margin: "auto",
    height: 48,
    borderRadius: 8,
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
  },
}));

const LinkExpiredPage = (props) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <Grid
        component={Paper}
        style={{
          height: isMobile ? "85vh" : "100vh",
        }}
        item
        xs={12}
        sm={12}
        md={8}
        elevation={0}
      >
        <Nav
          detailOne={
            location.pathname.includes("staff")
              ? "Request again to become member"
              : "You can again "
          }
          detailTwo={"Sign Up"}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: isMobile ? "80vh" : "95vh",
          }}
        >
          <form
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              height: "60vh",
              margin: "1vh 0 5vh 0",
            }}
          >
            <Grid
              item
              xs={12}
              sm={7}
              md={10}
              elevation={0}
              spacing={3}
              direction="column"
              style={{ width: isMobile ? "100%" : "50%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <form className={classes.root}>
                  <LinkExpired errMsg={props?.errorMsg} />
                </form>
              </div>
            </Grid>
          </form>
        </div>
      </Grid>
    </>
  );
};

export default LinkExpiredPage;
