import React from "react";
import { isMobile } from "react-device-detect";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Logo from "../../components/preloggedin/Logo";
import Banking from "../../asset/images/SignIn/Banking.png";
import Marketing from "../../asset/images/SignIn/Marketing.png";
import Transaction from "../../asset/images/SignIn/Transaction.png";
import SidebarSVG from "../../asset/images/Staff Illustration.svg";

const useStyles = makeStyles((theme) => ({
  paraContain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "fit-content",
    marginBottom: "1rem",
  },
  headText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#fff",
    [theme.breakpoints.down(630)]: {
      fontSize: 12,
    },
  },
  subText: {
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.7)",
  },
  typo: {
    color: "#fff",
    fontSize: "1.2rem",
    [theme.breakpoints.down(700)]: {
      fontSize: "0.9rem",
    },
  },
}));

const Sidebar = () => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      style={{
        borderTopRightRadius: isMobile ? "0" : "24px",
        borderBottomRightRadius: isMobile ? "0" : "24px",
        backgroundColor: "#1A1A1A",
        height: isMobile ? "15vh" : "100vh",
      }}
    >
      <Box
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          height: "8vh",
          margin: isMobile ? "3.5vh 3vw 3.5vh 8vw" : "5vh 4vw 5vh 4vw",
        }}
      >
        <a href={process.env.REACT_APP_STATIC_LINK}>
          <Logo />
        </a>
      </Box>

      {!isMobile && (
        <Box
          borderRadius="30%"
          sx={{
            mx: "4vw",
            my: "10vh",
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            borderBottomRightRadius: "20",
            justifyContent: "space-between",
            marginTop: "-1rem",
          }}
        >
          <Box style={{ height: "10vh", marginBottom: "1rem" }}>
            <Typography className={classes.typo}>
              We are a <span style={{ color: "#ff7300" }}>Global Economy</span>{" "}
              that loves the Locals..!!
            </Typography>
          </Box>
          {window.location.href.includes("staff") ? (
            <>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <img src={SidebarSVG} alt="sidebar_illustration" />
              </Box>
              <Box>
                <Typography>
                  Welcome on board the Cogito Metaverse where we are all working
                  together for a better future.
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.paraContain}>
                <img
                  src={Banking}
                  style={{
                    width: 26,
                    marginBottom: "0.5rem",
                  }}
                />
                <Typography className={classes.headText}>
                  Banking Facilities
                </Typography>
                <Typography className={classes.subText}>
                  Now that your are logged in, you can access all of your
                  banking requirements. You will be able to check all your
                  banking transactions and balances.
                </Typography>
              </Box>

              <Box className={classes.paraContain}>
                <img
                  src={Marketing}
                  style={{
                    width: 26,
                    marginBottom: "0.5rem",
                  }}
                />
                <Typography className={classes.headText}>
                  The Affiliate Program
                </Typography>
                <Typography className={classes.subText}>
                  Have a look at the affiliate program. Everytime that anyone
                  uses your affiliate link you will earn 25 Cog.
                </Typography>
              </Box>
              <Box className={classes.paraContain}>
                <img
                  src={Transaction}
                  style={{
                    width: 26,
                    marginBottom: "0.5rem",
                    // paddingBottom: 5,
                  }}
                />
                <Typography className={classes.headText}>
                  Transaction Talks
                </Typography>
                <Typography className={classes.subText}>
                  Once you have downloaded the Cogito POS app on your smart
                  phone, then transactions can be carried out using either your
                  smartphone or your Internet banking account.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default Sidebar;
