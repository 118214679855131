import React, { useEffect } from "react";
import {
 Container,
 Grid,
 Typography,
 Box,
 IconButton,
 Link,
} from "@material-ui/core";
import { connect } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import * as actionCreator from "../../../store/action/index";

const UserStaticPage = (props) => {
 const { logout } = props;
 useEffect(() => {
  setTimeout(() => {
   logout();
  }, [3000]);
 }, []);

 return (
  <Container>
   <Box sx={{ flexGrow: 1, mt: 5 }}>
    <Grid container spacing={2} direction="column" alignItems="center">
     <Grid item xs={12} sm={6}>
      <IconButton
       aria-label="account of current user"
       aria-controls="menu-appbar"
       aria-haspopup="true"
       // onClick={handleMenu}
       style={{ color: "#359DB6" }}
      >
       <AccountCircle fontSize="large" />
      </IconButton>
     </Grid>
     <Grid item xs={12} sm={8}>
      <Typography variant="body1" paragraph>
       We are sorry to see you go. Your account has been successfully deleted.
       If you have any further inquiries or require assistance, please do not
       hesitate to contact our support team at{" "}
       <Link href="mailto:support@principalityofcogito.com">
        support@principalityofcogito.com
       </Link>
       . Thank you.
      </Typography>
     </Grid>
    </Grid>
   </Box>
  </Container>
 );
};

const mapStateToProps = ({ authReducer, userReducer }) => {
 return {};
};

const mapDispatchToProps = (dispatch) => {
 return {
  logout: () => dispatch(actionCreator.OnLogout()),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStaticPage);
