import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import UserStaticPage from "./userStaticPage";
import * as actionCreator from "../../../store/action/index";

const ProfileStaticPage = (props) => {
 const history = useHistory();
 const token = useParams().token;
 const { deleteProfile, profile, notify, logout } = props;
 const [showComponent, setShowComponent] = useState(false);

 useEffect(() => {
  deleteProfile({
   token,
  })
   .then((res) => {
    const { status, successMessage, errorMessage } = res;
    if (status) {
     setShowComponent(true);
     notify(successMessage, "success");
    } else {
     history.push("/");
     notify(errorMessage, "error");
    }
   })
   .catch((err) => {
    history.push("/");
    notify(err?.errorMessage || "Delete Failed", "error");
   });
 }, []);

 return showComponent && <UserStaticPage />;
};

const mapStateToProps = ({ authReducer, userReducer }) => {
 return {
  profile: userReducer.profile,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  deleteProfile: (data) => dispatch(actionCreator.deleteProfile(data)),
  logout: () => dispatch(actionCreator.OnLogout()),
  notify: (message, varient) =>
   dispatch(actionCreator.notify(message, varient)),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStaticPage);
