import * as actionTypes from "./actionTypes";
import axios from "axios";

import { setWithExpiry, getWithExpiry } from "../../asset/utility";
import { notify } from "./notifyAction";
import { CollectionsBookmarkRounded } from "@material-ui/icons";
import callApi, { callApi_multipart, API } from "../../asset/api";

const setStatus = (data) => {
  return {
    type: actionTypes.SET_STATUS,
    payload: data,
  };
};

export const setUserState = (data) => {
  return {
    type: actionTypes.SET_USER_STATE,
    payload: data,
  };
};

export const setUserLoading = (data) => {
  return {
    type: actionTypes.SET_USER_LOADING,
    payload: data,
  };
};

const setUser = (data) => {
  return {
    type: actionTypes.SET_USER,
    payload: data,
  };
};

const setAccounts = (data) => {
  return {
    type: actionTypes.SET_ACCOUNT,
    payload: data,
  };
};

const setCryptopiaData = (data) => {
  return {
    type: actionTypes.SET_CRYPTOPIA_DATA,
    payload: data,
  };
};

const setBankStats = (data) => {
  return {
    type: actionTypes.SET_BANK_STATS,
    payload: data,
  };
};

const setKycToken = (data) => {
  return {
    type: actionTypes.SET_KYC_TOKEN,
    payload: data,
  };
};

const setKycId = (data) => {
  return {
    type: actionTypes.SET_KYC_ID,
    payload: data,
  };
};

const setSponsorList = (data) => {
  return {
    type: actionTypes.SET_SPONSOR_LIST,
    payload: data,
  };
};

const setOverDrafts = (data) => {
  return {
    type: actionTypes.SET_OVER_DRAFTS,
    payload: data,
  };
};

const setActiveOverDrafts = (data) => {
  return {
    type: actionTypes.SET_ACTIVE_OVERDRAFTS,
    payload: data,
  };
};

const setCompany = (data) => {
  return {
    type: actionTypes.SET_COMPANY_DETAILS,
    payload: data,
  };
};

const setBusinesses = (data) => {
  return {
    type: actionTypes.SET_BUSINESSES,
    payload: data,
  };
};

const setFilledData = (data) => {
  return {
    type: actionTypes.SET_FILLEDDATA,
    payload: data,
  };
};

const setQuizQuestions = (data) => {
  return {
    type: actionTypes.SET_QUIZ_QUESTION,
    payload: data,
  };
};

const setBankAccounts = (data) => {
  return {
    type: actionTypes.SET_BANK_ACCOUNTS,
    payload: data,
  };
};

const setUserKyc = (data) => {
  return {
    type: actionTypes.SET_USER_KYC,
    payload: data,
  };
};

const setIsCryptopiaDataAvailable = (data) => {
  return {
    type: actionTypes.SET_CRYPTOPIA_DATA_AVAILABLE,
    payload: data,
  };
};

export const saveCompanyData = (data) => {
  return async (dispatch, getState) => {
    dispatch(setFilledData(data));
  };
};

export const getProfile = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setUserLoading({
        getProfile: true,
      })
    );
    callApi("GetProfile", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, user, error } = res;
        dispatch(
          setUser({
            user: user,
          })
        );
        dispatch(
          setUserLoading({
            getProfile: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getProfile: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getAccounts = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(
      setUserLoading({
        getAccounts: true,
      })
    );
    callApi("GetAccounts", "POST")
      .then((res) => {
        const {
          status,
          successMessage,
          errorMessage,
          accounts,
          error,
          bankStats,
        } = res;
        dispatch(
          setAccounts({
            accounts: accounts,
          })
        );
        dispatch(
          setBankStats({
            cogStats: bankStats.cog_stats,
            cbcCirculation: bankStats.cbc_circulation,
          })
        );
        dispatch(
          setUserLoading({
            getAccounts: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getAccounts: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const updateUser = ({ changes }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let companyChange = false;
    const reqBody = {
      changes: changes,
    };

    //check if company changes
    for (const property in changes) {
      if (property.includes("company")) companyChange = true;
    }
    {
      companyChange
        ? dispatch(setUserLoading({ updateCompany: true }))
        : dispatch(setUserLoading({ updateProfile: true }));
    }

    callApi("UpdateUser", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (successMessage.length) dispatch(notify(successMessage, "success"));
        if (errorMessage.length) dispatch(notify(errorMessage, "error"));
        {
          companyChange
            ? dispatch(setUserLoading({ updateCompany: false }))
            : dispatch(setUserLoading({ updateProfile: false }));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
        dispatch(getProfile());
      })
      .catch((err) => {
        {
          companyChange
            ? dispatch(setUserLoading({ updateCompany: false }))
            : dispatch(setUserLoading({ updateProfile: false }));
        }
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const updatePassword = ({ currentPassword, newPassword }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    const reqBody = {
      currentPassword: currentPassword,
      password: newPassword,
    };
    dispatch(setUserLoading({ updatePassword: true }));
    callApi("UpdatePassword", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (successMessage.length) dispatch(notify(successMessage, "success"));
        if (errorMessage.length) dispatch(notify(errorMessage, "error"));
        dispatch(setUserLoading({ updatePassword: false }));
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ updatePassword: false }));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const confirmEmailUpdateToken = (token) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      token: token,
    };
    dispatch(setUserLoading({ updateEmail: true }));
    callApi("UpdateEmail", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (successMessage.length) dispatch(notify(successMessage, "success"));
        if (errorMessage.length) dispatch(notify(errorMessage, "error"));
        dispatch(setUserLoading({ updateEmail: false }));
        if (status) dispatch(getProfile());
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ updateEmail: false }));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getKycToken = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  // here we are setting kyc token and kyc Id
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let kyc = getState().userReducer.kyc;
    dispatch(
      setUserLoading({
        getKycToken: true,
      })
    );
    callApi("GetKycToken", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, token, error, userId } =
          res;
        if (status) {
          dispatch(
            setKycToken({
              token: token,
            })
          );
          dispatch(
            setUserLoading({
              getKycToken: false,
            })
          );

          if (kyc?.time) {
            callApi("GetKYCdata", "POST", { userId: userId })
              .then((res) => {
                const { status, errorMessage, successMessage, error, id } = res;
                if (status) {
                  dispatch(
                    setKycId({
                      id: id,
                    })
                  );
                }
                if (error) {
                  dispatch(
                    setStatus({
                      error: error,
                    })
                  );
                }
              })
              .catch((err) => {
                dispatch(
                  setStatus({
                    error: err?.response?.data.error || err,
                  })
                );
                dispatch(
                  notify(
                    err?.response?.data?.errorMessage ||
                      "Please try again by refresh..!",
                    "error"
                  )
                );
              });
          }
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getKycToken: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const addContacts = (data) => {
  return new Promise(async (resolve, reject) => {
    await callApi("addContacts", "POST", { contacts: data })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inviteContact = (data) => {
  const { dispatch } = data;
  return new Promise(async (resolve, reject) => {
    await callApi("inviteContact", "POST", {
      contacts: data.contacts,
      inviteContact: data.inviteContact,
      from: data.from,
      referalLink: data.referalLink,
    })
      .then((response) => {
        if (response?.errorMessage) {
          dispatch(
            notify(
              response?.errorMessage || "Please try again by refresh..!",
              "error"
            )
          );
        } else {
          dispatch(
            notify(
              `Invitation sent to ${data?.inviteContact?.email}`,
              "success"
            )
          );
        }
        resolve(response);
      })
      .catch((err) => {
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
        reject(err);
      });
  });
};

// not used
export const setKycStatus = ({ status, reviewDate }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    const reqBody = {
      status: status,
      reviewDate: reviewDate,
    };

    callApi("SetKycStatus", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProfile());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const registerUserForKyc = ({ email, phone, info }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    const reqBody = {
      email: email,
      phone: phone,
      info: info,
    };

    callApi("CreateKycUser", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "info"));
          dispatch(getProfile());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const setEscrowWindow = (data) => {
  return {
    type: actionTypes.SET_ESCROW_WINDOW,
    payload: data,
  };
};

const addRecommend = (data) => {
  return {
    type: actionTypes.ADD_RECOMMENDS,
    payload: data,
  };
};

export const getInitialRecommends = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setUserLoading({ getRecommendation: true }));
    const reqBody = {};
    callApi("getInitialRecommends", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, user, error } = res;
        if (user) {
          dispatch(addRecommend({ user: user }));
        }
        dispatch(
          setUserLoading({
            getRecommendation: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getRecommendation: false,
          })
        );

        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getRecommends = (searchUser) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(
      setUserLoading({
        getRecommendation: true,
      })
    );
    const reqBody = {
      searchUser: searchUser,
    };
    callApi("getRecommends", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, user, error } = res;
        dispatch(
          addRecommend({
            user: user,
          })
        );
        dispatch(
          setUserLoading({
            getRecommendation: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getRecommendation: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const transferFunds = ({ fromAccountId, amount, pin, note }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(
      setUserLoading({
        transferCogs: true,
      })
    );
    const reqBody = {
      fromAccountId: fromAccountId,
      amount: amount,
      pin: pin,
      note: note ? note : "",
    };

    callApi("TransferFunds", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getAccounts());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setUserLoading({
            transferCogs: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            transferCogs: false,
          })
        );
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const ResetPin = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        resetPin: true,
      })
    );
    //# HERE MAKE ASYNC CALLS.
    const reqBody = {};

    callApi("ResetPin", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(setUserLoading({ resetPin: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ resetPin: false }));
        }

        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
        dispatch(
          setUserLoading({
            resetPin: false,
          })
        );
      });
  };
};

export const SetPin = ({ oldPin, newPin }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(
      setUserLoading({
        setPin: true,
      })
    );
    const reqBody = {
      oldPin: oldPin,
      newPin: newPin,
    };

    callApi("SetPin", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
        dispatch(
          setUserLoading({
            setPin: false,
          })
        );
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
        dispatch(
          setUserLoading({
            setPin: false,
          })
        );
      });
  };
};

export const PayCogs = ({
  to_profile_id,
  amount,
  pin,
  note,
  local_currency,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(
      setUserLoading({
        payCogs: true,
      })
    );
    const reqBody = {
      to_profile_id: to_profile_id,
      amount: amount,
      pin: pin,
      note: note,
      local_currency: local_currency,
    };

    callApi("PayCogs", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getAccounts());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setUserLoading({
            payCogs: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            payCogs: false,
          })
        );
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const rechargeEuro = (order_id) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(
      setUserLoading({
        euroRecharge: true,
      })
    );
    const reqBody = {
      order_id: order_id,
    };
    callApi("EuroRecharge", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getAccounts());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setUserLoading({
            euroRecharge: false,
          })
        );
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            euroRecharge: false,
          })
        );
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const getSponsorList = () => {
  return async (dispatch, getState) => {
    const reqBody = {};

    callApi("GetSponsorInfo", "POST", reqBody)
      .then((res) => {
        const { status, errorMessage, successMessage, list, error } = res;
        if (status) {
          dispatch(setSponsorList({ sponsorList: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const getOverDrafts = () => {
  return async (dispatch, getState) => {
    const reqBody = {};

    callApi("GetOverDrafts", "POST", reqBody)
      .then((res) => {
        const { status, errorMessage, successMessage, overdrafts, error } = res;
        if (status) {
          dispatch(setOverDrafts({ overdrafts: overdrafts.overdraft_types }));
          dispatch(
            setActiveOverDrafts({
              activeOverdrafts: overdrafts.assigned_overdraft,
            })
          );
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        if (error) {
          dispatch(
            setStatus({
              error: error,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setStatus({
            error: err,
          })
        );
      });
  };
};

export const registerCompany = (data) => {
  return async (dispatch, getState) => {
    const reqBody = data;

    dispatch(setUserLoading({ registerCompany: true }));

    callApi("registerCompany", "POST", reqBody)
      .then((res) => {
        const { status, errorMessage, successMessage, error } = res;
        if (status) {
          dispatch(getCompanies());
          dispatch(notify(successMessage, "success"));
          dispatch(setUserLoading({ registerCompany: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ registerCompany: false }));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(setUserLoading({ registerCompany: false }));
      });
  };
};

export const getCompanies = () => {
  return async (dispatch, getState) => {
    callApi("getCompanies", "POST", {})
      .then((res) => {
        const { status, errorMessage, successMessage, companies, error } = res;
        if (status) {
          dispatch(setCompany(companies));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
      });
  };
};

export const getBusinesses = () => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getBusinesses: true,
      })
    );
    callApi("GetBusiness", "POST", {})
      .then((res) => {
        const { status, errorMessage, successMessage, business, error } = res;
        if (status && business) {
          dispatch(setBusinesses({ business }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setUserLoading({
            getBusinesses: false,
          })
        );
      })
      .catch((err) => {
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
        dispatch(
          setUserLoading({
            getBusinesses: false,
          })
        );
      });
  };
};

export const createFamilyMember = (req) => {
  return async (dispatch, getState) => {
    setUserLoading(true);
    let reqBody = {
      from: { relation: req.fromRelation },
      to: { id: req.toId, relation: req.relations },
    };
    dispatch(setUserLoading({ createFamilyMember: true }));
    callApi("CreateFamilyMember", "POST", reqBody)
      .then((res) => {
        setUserLoading(false);
        const { status, error, successMessage, errorMessage } = res;
        if (status) {
          dispatch(getProfile());

          dispatch(notify(successMessage, "success"));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        setUserLoading(false);
        dispatch(notify(err?.response?.data?.errorMessage, "error"));
      });
  };
};

export const getAssetList = () => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("getUserAssets", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        // console.log("checkingGetUserAssets", res);
        if (status && data?.balances) {
          let list = data?.balances || null;
          dispatch(setAssetsList({ data: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getTraderList: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};
export const updateFamilyStatus = (req) => {
  return async (dispatch, getState) => {
    const reqBody = {
      from: req?.from,
      to: req?.to,
      status: req?.status,
      fromRelation: req?.fromRelation,
    };
    dispatch(setUserLoading({ updateFamilyStatus: true }));
    callApi("updateFamilyStatus", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (successMessage.length) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProfile());
        }

        if (errorMessage.length) dispatch(notify(errorMessage, "error"));
        dispatch(setUserLoading({ updateFamilyStatus: false }));
        if (error) {
          dispatch(setStatus({ error: error }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ updateFamilyStatus: false }));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const registerStocksAccount = ({ name, email, password }) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading(true));
    const reqBody = {
      name: name,
      email: email,
      password: password,
    };
    callApi("registerStocksAccount", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify(successMessage, "success"));
          dispatch(getProfile());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        dispatch(setUserLoading(false));
      })
      .catch((err) => {
        dispatch(
          setStatus({
            error: err?.response?.data?.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
        dispatch(setUserLoading(false));
      });
  };
};

const setTraderList = (data) => {
  return {
    type: actionTypes.SET_TRADERS,
    payload: data,
  };
};
const setAssetsList = (data) => {
  return {
    type: actionTypes.SET_ASSETS,
    payload: data,
  };
};
const setUserAccount = (data) => {
  return {
    type: actionTypes.SET_USER_ACCOUNT,
    payload: data,
  };
};
const setEscrowAccount = (data) => {
  return {
    type: actionTypes.SET_ESCROW_ACCOUNT,
    payload: data,
  };
};

const setUserUriGroupId = (data) => {
  return {
    type: actionTypes.SET_USER_URI_GROUP_ID,
    payload: data,
  };
};

const setUserUriDetail = (data) => {
  return {
    type: actionTypes.SET_USER_URI_DETAIL,
    payload: data,
  };
};
const setAllAssetsList = (data) => {
  return {
    type: actionTypes.SET_ALL_ASSETS,
    payload: data,
  };
};
export const getTraderList = () => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("getTraders", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status && data.status == "200 OK") {
          let list = data?.data || null;
          dispatch(setTraderList({ data: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getTraderList: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setTrader = (data) => {
  return {
    type: actionTypes.SET_TRADER,
    payload: data,
  };
};
export const getTrader = (data) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("getTrader", "POST", data)
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status && data.status == "200 OK") {
          let list = data?.data || null;
          dispatch(setTrader({ data: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getTraderList: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const createTrader = (data, callBack = () => {}) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("createTrader", "POST", data)
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status && data.status == "200 OK") {
          dispatch(notify("Trader created successfull", "success"));
          callBack();
        } else {
          let response = data?.data;
          // console.log(response);
          dispatch(notify(response?.message || errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const withdrawStockExchange = (data) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        stocksTransaction: true,
      })
    );
    callApi("withdrawstockexchange", "POST", data)
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(getAccounts());
          dispatch(getAssetList());
          dispatch(
            notify(successMessage || "Transaction successfull", "success")
          );
        } else {
          dispatch(
            notify(
              error?.message ||
                error ||
                errorMessage ||
                "Something went wrong!",
              "error"
            )
          );
        }
        dispatch(setUserLoading({ stocksTransaction: false }));
      })
      .catch((err) => {
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(setUserLoading({ stocksTransaction: false }));
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const depositStockExchange = (data) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        stocksTransaction: true,
      })
    );
    callApi("depositstockexchange", "POST", data)
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(getAccounts());
          dispatch(getAssetList());
          dispatch(
            notify(successMessage || "Transaction successfull", "success")
          );
        } else {
          dispatch(
            notify(
              error?.message ||
                error ||
                errorMessage ||
                "Something went wrong!",
              "error"
            )
          );
        }
        dispatch(setUserLoading({ stocksTransaction: false }));
      })
      .catch((err) => {
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(setUserLoading({ stocksTransaction: false }));
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getAllAssetList = () => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("getAssets", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status && data?.status == "200 OK") {
          let list = data?.data || null;
          dispatch(setAllAssetsList({ data: list }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getTraderList: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const optinAsset = (data) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        optinCall: true,
      })
    );
    // console.log("data: ", data);
    const reqBody = {
      assetId: data.assetId,
      accountId: data.accountId,
    };
    callApi("optinstockexchange", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(getAllAssetList());
          dispatch(getStockAccount());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ optinCall: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            optinCall: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getStockAccount = () => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getTraderList: true,
      })
    );
    callApi("getStockAccount", "POST")
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status && data?.data && data?.status == "200 OK") {
          dispatch(setUserAccount({ data: data?.data }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getTraderList: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getTraderList: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setExchangeDirectory = (data) => {
  return {
    type: actionTypes.UPDATE_USER_REDUCER,
    payload: data,
  };
};

export const getExchangeDirectory = (request) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        exchangeDirectory: true,
      })
    );
    callApi("getExchangeDirectory", "POST", request || {})
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          let list = data || [];
          dispatch(setExchangeDirectory({ exchangeDirectory: list || [] }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ exchangeDirectory: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            exchangeDirectory: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getEscrowAccount = (payload = null) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        getEscrowAccounts: true,
      })
    );
    let reqBody = payload;
    callApi("getEscrowAccounts", "POST", payload !== null ? reqBody : {})
      .then((res) => {
        const { status, successMessage, errorMessage, data } = res;
        if (status) {
          dispatch(setEscrowAccount({ data: data }));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ getEscrowAccounts: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            getEscrowAccounts: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const cancelEscrow = ({ profile_id, escrow_id, type, action }) => {
  return async (dispatch, getState) => {
    dispatch(
      setUserLoading({
        cancelEscrow: true,
      })
    );
    const reqBody = {
      profile_id: profile_id,
      escrow_id: escrow_id,
      type: type,
      action: action,
    };
    callApi("cancelEscrow", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, data } = res;
        if (status) {
          dispatch(getEscrowAccount());
          dispatch(notify(successMessage, "success"));
        } else {
          dispatch(notify(errorMessage, "error"));
        }
        dispatch(setUserLoading({ cancelEscrow: false }));
      })
      .catch((err) => {
        dispatch(
          setUserLoading({
            cancelEscrow: false,
          })
        );
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const updateEscrowConditions = (req) => {
  return async (dispatch, getState) => {
    const reqBody = {
      conditions: req?.conditions,
      escrow_id: req?.escrow_id,
      type: req?.type,
      conditions_type: req?.conditions_type,
    };
    callApi("updateEscrowConditions", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (successMessage.length) {
          dispatch(notify(successMessage, "success"));
          dispatch(getEscrowAccount());
          dispatch(setUserLoading({ updateEscrowConditions: true }));
        }

        if (errorMessage.length) dispatch(notify(errorMessage, "error"));
        dispatch(setUserLoading({ updateEscrowConditions: false }));
        if (error) {
          dispatch(setStatus({ error: error }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ updateEscrowConditions: false }));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
          })
        );
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const updateEscrowConditionsCallback = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        conditions: req?.conditions,
        escrow_id: req?.escrow_id,
        type: req?.type,
        conditions_type: req?.conditions_type,
      };
      callApi("updateEscrowConditions", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const bankingPay = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let reqBody = {
        to_profile_id: req?.to_profile_id,
        amount: req?.amount,
        pin: req?.pin,
        note: req?.note,
        local_currency: req?.local_currency,
      };

      // Handle the Multipay case
      if (req?.endpoint === "SplitCogCbcPayment") {
        reqBody = {
          ...reqBody,
          cog_percentage: req?.cog_percentage,
          cbc_percentage: req?.cbc_percentage,
        };
      }

      callApi(req?.endpoint, "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

// export const bankingPay = (req) => {
//   return (dispatch, getState) => {
//     return new Promise((resolve, reject) => {
//       const reqBody = {
//         to_profile_id: req?.to_profile_id,
//         amount: req?.amount,
//         pin: req?.pin,
//         note: req?.note,
//         local_currency: req?.local_currency,
//       };
//       callApi(req?.endpoint, "POST", reqBody)
//         .then(async (res) => {
//           const { status, successMessage, errorMessage, error } = res;
//           if (status) {
//             resolve(res);
//           } else {
//             reject(errorMessage);
//           }
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     });
//   };
// };

export const escrowPay = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        seller_profile_id: req?.seller_profile_id,
        account_type_id: req?.account_type_id,
        amount: req?.amount,
        pin: req?.pin,
        title: req?.title,
        description: req?.description,
        buyer_conditions: req?.buyer_conditions,
      };
      callApi("initiateEscrow", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const multiPay = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        to_profile_id: req?.to_profile_id,
        amount: req?.amount,
        pin: req?.pin,
        note: req?.note,
        local_currency: req?.local_currency,
      };
      callApi(req?.endpoint, "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const transferPay = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        escrow_id: req?.escrow_id,
        profile_id: req?.profile_id,
        pin: req?.pin,
      };
      callApi("transferbuyertoescrow", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const completePay = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        escrow_id: req?.escrow_id,
        profile_id: req?.profile_id,
        pin: req?.pin,
      };
      callApi("completeEscrow", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const markEscrowRead = (req) => {
  return (dispatch, getState) => {
    const reqBody = {
      escrowId: req?.escrowId,
      profileId: req?.profileId,
      type: req?.type,
    };
    callApi("markescrowread", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(getEscrowAccount());
        } else {
          notify(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getUserUriDetail = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("getUserUriDetail", "POST")
        .then((res) => {
          const { status, data, errorMessage } = res;
          if (status) {
            resolve(data);
            dispatch(setUserUriDetail({ data: data }));
            dispatch(setUserUriGroupId({ data: data?.user_group?.Id }));
            // dispatch(setUserUriGroupId({ data: data?.group_id }));
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const userOptURI = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_name: req?.group_name,
        description: req?.description,
      };
      callApi("userOptURI", "POST", reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
            dispatch(setUserUriGroupId({ data: data?.user_group?.Id }));
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const UploadDocs = (formData) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = formData;
      API.post("/UploadDocs", reqBody, {
        "Content-Type": "multipart/form-data",
      })
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const updateURIGroup = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        id: req?.id,
        name: req?.name,
        description: req?.description,
        group_pic: req?.group_pic,
      };
      callApi("updateURIGroup", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const cancelURIMemberInvite = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("cancelURIMemberInvite", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const declineURIMemberInvite = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("declineURIMemberInvite", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const acceptURIMemberInvite = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("acceptURIMemberInvite", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const leaveURIGroup = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("leaveURIGroup", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const addMember = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("inviteURIMember", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(notify(successMessage, "success"));
            getUserUriDetail();
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const removeMember = (req) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        group_id: req?.group_id,
        member_profile_id: req?.member_profile_id,
      };
      callApi("removeURIMember", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(notify(successMessage, "success"));
            dispatch(getUserUriDetail());
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const inviteUserForURI = ({ email, name }) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        email_id: email,
        name: name,
      };

      callApi("inviteUserForUri", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const getQuizQuestions = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        level: data.level,
        submodule: data.submodule,
      };
      callApi("getQuizQuestion", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
            dispatch(setQuizQuestions(data));
            // dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const submitQuestion = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        quizDetail: data.userAnswerArray,
      };
      callApi("submitQuizQuestion", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          data.setUserAnswerArray([]);
          if (status) {
            resolve(res);
            dispatch(notify(successMessage, "success"));
            if (data.currentSubmodule !== 9) {
              const reqBody = {
                level: data.currentModule,
                submodule: data.currentSubmodule + 1,
              };
              dispatch(getQuizQuestions(reqBody));
            }
            dispatch(getUserUriDetail());
            data.closeModule();
          } else {
            reject(errorMessage);
            data.setUserAnswerArray([]);
            data.closeModule();
            dispatch(getUserUriDetail());
            dispatch(notify(error, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

const setStockList = (data) => {
  return { type: actionTypes.SET_STOCK_LIST, payload: data };
};
const setStocksEngineList = (data) => {
  return { type: actionTypes.SET_STOCK_ENGINE_LIST, payload: data };
};

const setWalletData = (data) => {
  return { type: actionTypes.SET_STOCK_WALLET_DATA, payload: data };
};

const setTransactionData = (data) => {
  return { type: actionTypes.SET_STOCK_TRANSACTION_DATA, payload: data };
};

const setTransactionExportData = (data) => {
  return { type: actionTypes.SET_STOCK_TRANSACTION_EXPORT_DATA, payload: data };
};

const setWalletTransactionData = (data) => {
  return { type: actionTypes.SET_STOCK_WALLET_TRANSACTION_DATA, payload: data };
};

const setWalletTransactionExportData = (data) => {
  return {
    type: actionTypes.SET_WALLET_TRANSACTION_EXPORT_DATA,
    payload: data,
  };
};

const setStockPrice = (data) => {
  return { type: actionTypes.SET_STOCK_PRICE_DATA, payload: data };
};

const setStockCategory = (data) => {
  return { type: actionTypes.SET_STOCK_CATEGORY_DATA, payload: data };
};

const setSingleStockPriceArr = (data) => {
  return { type: actionTypes.SET_SPECIFIC_STOCK_PRICE_ARR, payload: data };
};

export const getStocks = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockList: true }));
    callApi("getStocks", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          let list = data || [];
          dispatch(setStockList({ stockList: data || [] }));
          dispatch(setUserLoading({ stockList: false }));
        } else {
          dispatch(setUserLoading({ stockList: false }));
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const buyStocks = (req) => {
  return async (dispatch, getState) => {
    const reqBody = {
      stockId: req?.stockId,
      quantity: Number(req?.quantity),
      pin: Number(req?.pin),
    };
    return callApi("buyStocks", "POST", reqBody)
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        if (status) {
          dispatch(notify("Stocks purchased successfully", "success"));
          dispatch(getStocks());
          dispatch(getUserStocks());
          dispatch(getAccounts());
          return res;
        } else {
          dispatch(notify(errorMessage, "error"));
          return res;
        }
      })
      .catch((err) => {
        return err;
      });
  };
};

const setUserStocks = (data) => {
  return {
    type: actionTypes.SET_USER_STOCKS,
    payload: data,
  };
};

export const getUserStocks = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stocksOwned: true }));
    callApi("getUserStocks", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(setUserStocks({ userStocks: data }));
          dispatch(setUserLoading({ stocksOwned: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({}));
          dispatch(setUserLoading({ stocksOwned: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stocksOwned: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setStockTransactions = (data) => {
  return {
    type: actionTypes.SET_STOCK_TRANSACTIONS,
    payload: data,
  };
};
export const getUserStocksTransaction = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockTransaction: true }));
    callApi("getStocksTransaction", "POST", request || {})
      .then((res) => {
        const {
          status,
          successMessage,
          errorMessage,
          error,
          data,
          totalCount,
        } = res;
        if (status) {
          dispatch(
            setStockTransactions({
              userStocksTransations: data,
              totalCount: totalCount,
            })
          );
          dispatch(setUserLoading({ stockTransaction: false }));
        } else {
          dispatch(setUserLoading({ stockTransaction: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockTransaction: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const createStockAccount = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockAccount: true }));
    callApi("createUserStocks", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, id } = res;
        if (status) {
          dispatch(setUserLoading({ stockAccount: false }));
          dispatch(notify("Stock Account Created", "success"));
          dispatch(getStocks());
        } else {
          dispatch(setUserLoading({ stockAccount: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockAccount: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const createStockAccountEngine = (data) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockEngineAccount: true }));
    callApi("createEngineProfile", "POST", {
      acceptedEngineTerms: data.acceptedEngineTerms,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error, id } = res;
        if (status) {
          dispatch(setUserLoading({ stockEngineAccount: false }));
          dispatch(notify("Stock Account Created", "success"));
          data.callBack(0);
          dispatch(getStocks());
          dispatch(getProfile());
        } else {
          dispatch(setUserLoading({ stockEngineAccount: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockEngineAccount: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getCryptopiaAgreementForm = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUserLoading({ cryptopiaForm: true }));
      callApi("getCryptopiaAgreementForm", "POST", request || [])
        .then((res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            dispatch(setUserLoading({ cryptopiaForm: false }));
            dispatch(setCryptopiaData({ cryptopiaData: data }));
            resolve(res);
          } else {
            dispatch(setUserLoading({ cryptopiaForm: false }));
            reject(errorMessage);
          }
        })
        .catch((err) => {
          dispatch(setUserLoading({ cryptopiaForm: false }));
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const updateCryptopiaAgreementForm = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        name: request?.name,
        address: request?.address,
        mobile: request?.mobile,
        email: request?.email,
        cryptopiaEmail: request?.cryptopiaEmail,
        saleOption: request?.saleOption,
        coinsType: request?.coinsType,
        coinsValue: request?.coinsValue,
      };
      dispatch(setUserLoading({ updateCryptopiaAgreementForm: true }));
      callApi("updateCryptopiaAgreementForm", "POST", reqBody || [])
        .then((res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            dispatch(setUserLoading({ updateCryptopiaAgreementForm: false }));
            dispatch(setCryptopiaData({ cryptopiaData: data }));
            resolve(res);
          } else {
            dispatch(setUserLoading({ updateCryptopiaAgreementForm: false }));
            dispatch(notify(errorMessage, "error"));
            reject(errorMessage);
          }
        })
        .catch((err) => {
          dispatch(setUserLoading({ updateCryptopiaAgreementForm: false }));
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const sendverifyemailotp = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("sendverifyemailotp", "POST", request || [])
        .then((res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const verifycryptopiaemail = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("verifycryptopiaemail", "POST", request || [])
        .then((res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const SendNewAgreement = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ sendAgreement: true }));
    callApi("sendNewAgreement", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          // resolve(res)
          dispatch(setUserLoading({ sendAgreement: false }));
          dispatch(notify("Sent New Agreement successfully", "success"));
          dispatch(getCryptopiaAgreementForm());
        } else {
          // reject(status);
          //  console.log(errorMessage);
          dispatch(setUserLoading({ sendAgreement: false }));
          dispatch(
            notify(errorMessage || "Error while sending new agreement", "error")
          );
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ sendAgreement: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
    // });
  };
};

export const createBankAccount = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        accountName: data.accountName,
        userName: data.userName,
        bankAddress: data.bankCommunicationAddress,
        bankName: data.bankname,
        bankAccountNumber: data.bankaccountnumber,
        swiftCode: data.swiftcode.toUpperCase().trim(),
        iban: data.iban.toUpperCase().trim(),
      };
      callApi("createBankAccount", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(getBankAccount({}));
            data.goBack();
            dispatch(notify("Bank Account Created Successfully!", "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const getBankAccount = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let reqBody;
      if (data !== {}) {
        //   reqBody = {
        //    bankName: data.bankname,
        //    bankAccountNumber: data.bankaccountnumber,
        //    swiftCode: data.swiftcode,
        //    status: data.bankstatus,
        //    id: data.bankid,
        //   };

        //  } else {

        reqBody = {};
      }
      callApi("getBankAccount", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
            dispatch(setBankAccounts(data));
            // dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const deleteBankAccount = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        id: data,
      };
      callApi("deleteBankAccount", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(getBankAccount());
            dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const initiatecbcbanktransfer = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const reqBody = {
        isBuy: data.isBuy,
        amount: Number(data.amount),
        bankAccountId: data.bankAccountId,
      };
      callApi("initiatecbcbanktransfer", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(
              getcbcbanktransfers({
                profileId: getState()?.userReducer?.profile?._id,
              })
            );
            dispatch(getProfile());
            dispatch(getAccounts());
            dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const initiatecbctransfer = (data) => {
  return new Promise((resolve, reject) => {
    const reqBody = {
      ...data,
    };
    callApi("initiatecbctransfersession", "POST", reqBody)
      .then(async (res) => {
        const { status, successMessage, errorMessage, error } = res;
        console.log(res, "From Action");
        if (status) {
          resolve(res);
        } else {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getcbcbanktransfers = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let reqBody = {};
      if (data?.profileID) {
        reqBody = {
          profileId: data?.profileID,
        };
      }
      callApi("getcbcbanktransfers", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
            dispatch(setCbcbTransfers(data));
            // dispatch(getProfile());
            // dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

const setCbcbTransfers = (data) => {
  return {
    type: actionTypes.SET_CBC_TRANSFERS,
    payload: data,
  };
};

export const markTxnCompleted = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let reqBody = {};
      if (data?.transfer_id) {
        reqBody = {
          transfer_id: data.transfer_id,
        };
      }
      callApi("markBuyAsCompleted", "POST", reqBody)
        .then(async (res) => {
          const { status, successMessage, errorMessage, error, data } = res;
          if (status) {
            resolve(res);
            dispatch(
              getcbcbanktransfers({
                profileID: getState()?.userReducer?.profile?._id,
              })
            );
            dispatch(notify(successMessage, "success"));
          } else {
            reject(errorMessage);
            dispatch(notify(errorMessage, "error"));
          }
        })
        .catch((err) => {
          reject(err);
          dispatch(notify(err?.message, "error"));
        });
    });
  };
};

export const getCryptopiaUserData = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("getCryptopiaUserData", "POST", request || [])
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(errorMessage);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

// KYC
export const applyKYC = (dataRequest) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    let data = new FormData();
    data.append("docType", dataRequest.doc);
    data.append("token", storedToken);

    for (let i = 0; i < dataRequest?.docs?.length; i++) {
      data.append("docs", dataRequest?.docs[i]);
    }

    for (let i = 0; i < dataRequest?.userVerificationPic?.length; i++) {
      data.append("userVerificationPic", dataRequest?.userVerificationPic[i]);
    }

    API.post("/applyKyc", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        if (status) {
          // dispatch(OnLogout());
          dispatch(notify(successMessage, "success"));
          dispatch(getKYC());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.message, "error"));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
      });
  };
};

export const getKYC = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    const reqBody = {};

    callApi("getKyc", "POST", reqBody)
      .then((res) => {
        const { data, status, successMessage, errorMessage, error } = res;
        dispatch(setUserKyc(data));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        // if (status) {
        //   // dispatch(OnLogout());
        //   dispatch(notify(successMessage, "success"));
        // }
        // else{
        //   dispatch(notify(errorMessage, "error"))
        // }
      })
      .catch((err) => {
        dispatch(notify(err?.message, "error"));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
      });
  };
};

export const updateKYC = (dataRequest) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    let data = new FormData();
    data.append("docType", dataRequest.doc);
    data.append("token", storedToken);

    for (let i = 0; i < dataRequest?.docs?.length; i++) {
      data.append("docs", dataRequest?.docs[i]);
    }

    for (let i = 0; i < dataRequest?.userVerificationPic?.length; i++) {
      data.append("userVerificationPic", dataRequest?.userVerificationPic[i]);
    }

    API.post("/updateKyc", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        if (status) {
          // dispatch(OnLogout());
          dispatch(notify(successMessage, "success"));
          dispatch(getKYC());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.message, "error"));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
      });
  };
};

export const uploadSignedLetter = (dataRequest) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");

    let data = new FormData();
    data.append("token", storedToken);
    data.append("docs", dataRequest?.docs);

    // console.log({ dataRequest });

    API.post("/uploadSignedCryptopiaDocument", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, successMessage, errorMessage, error } = res.data;
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        if (status) {
          // dispatch(OnLogout());
          dispatch(notify(successMessage, "success"));
          dispatch(getKYC());
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(notify(err?.message, "error"));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
      });
  };
};

export const getEngineProfile = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ stocksOwned: true }));
    callApi("getEngineProfile", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          // dispatch(setUserStocks({ userStocks: data }));
          // dispatch(setUserLoading({ stocksOwned: false }));
        } else {
          // dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({}));
          // dispatch(setUserLoading({ stocksOwned: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ stocksOwned: false }));
        // dispatch(setStatus({ error: err?.response?.data?.error || err }));
        // dispatch(
        //   notify(
        //     err?.response?.data?.error || "Please try again by refresh..!",
        //     "error"
        //   )
        // );
      });
  };
};

export const getPortfolioEngine = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ userStock: true }));
    callApi("getPortfolioEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(
            setUserStocks({
              singleUserStock: data.data,
              singleUserStockCount: data.count,
              portfolioDataCurrentPage: request.page,
            })
          );
          dispatch(setUserLoading({ userStock: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ userStock: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ userStock: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getAllStocksEngine = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockEngineList: true }));
    callApi("getAllStocksEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(
            setStocksEngineList({
              allStockEngineList: data.data || [],
              allStockDataCount: data.count,
              allStockDataCurrentPage: request.page,
            })
          );
          dispatch(setUserLoading({ stockEngineList: false }));
        } else {
          dispatch(setUserLoading({ stockEngineList: false }));
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockEngineList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const tradeStockEngine = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("tradeStockEngine", "POST", request)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const getEngineWallet = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ walletDataLoading: true }));
    callApi("getEngineWallet", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(setWalletData({ walletData: data }));
          dispatch(setUserLoading({ walletDataLoading: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ walletDataLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ walletDataLoading: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const depositEngineWallet = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setUserLoading({ depositWithdrawLoading: true }));
      callApi("depositEngineWallet", "POST", request)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
            dispatch(setUserLoading({ depositWithdrawLoading: false }));
          } else {
            reject(res);
            dispatch(setUserLoading({ depositWithdrawLoading: false }));
          }
        })
        .catch((err) => {
          dispatch(setUserLoading({ depositWithdrawLoading: false }));
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const withdrawEngineWallet = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("withdrawEngineWallet", "POST", request)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

export const getStockTxnEngine = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ transactionList: true }));
    callApi("getStockTxnEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setTransactionData({
              stockTransactionData: data.data,
              totalTransactionDataCount: data.count,
            })
          );
          dispatch(setUserLoading({ transactionList: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ transactionList: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ transactionList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getWalletTxnEngine = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ walletDataLoading: true }));
    callApi("getWalletTxnEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setWalletTransactionData({
              stockWalletTransactionData: data.data,
              totalWalletDataCount: data.count,
            })
          );
          dispatch(setUserLoading({ walletDataLoading: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ walletDataLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ walletDataLoading: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getStockPriceHistory = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockPrice: true }));
    callApi("getStockPriceHistory", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setStockPrice({
              stockPriceData: data,
            })
          );

          dispatch(setUserLoading({ stockPrice: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ stockPrice: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockPrice: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setOrderBook = (data) => {
  return { type: actionTypes.SET_ORDER_BOOK, payload: data };
};
export const getStockOrdeBook = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ stockPrice: true }));
    callApi("getStockOrdeBook", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setOrderBook({
              orderBook: data,
            })
          );
          // dispatch(setUserLoading({ stockPrice: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ stockPrice: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ stockPrice: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setNotifications = (data) => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    payload: data,
  };
};

export const getNotifications = (req) => {
  return async (dispatch, getState) => {
    callApi("getnotifications", "POST")
      .then((res) => {
        // dispatch(Notifications(true));
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          // dispatch(notify(successMessage, "Success"));
          dispatch(setNotifications({ data: data?.notifications }));
        } else {
          dispatch(notify(errorMessage || "error"));
          // dispatch(Notifications(false));
        }
      })
      .catch((err) => {
        dispatch(
          notify(
            err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
            "error"
          )
        );
        // dispatch(Notifications(false));
      });
  };
};

export const updateStockRealtime = (data) => {
  return async (dispatch, getState) => {
    const stockList = getState()?.userReducer?.allStockEngineList || [];
    const userStockList = getState()?.userReducer?.singleUserStock || [];
    const stockPriceData = getState()?.userReducer?.stockPriceData || [];
    const currentStockId = getState()?.userReducer?.currentStockId || null;
    const currentSingleStockObj =
      getState().userReducer?.singleStockPriceArr || {};

    const newArr = [];
    const newUserArr = [];
    let isUpdate = false;
    stockList.map((element) => {
      if (data?.stockId == element.stockId) {
        isUpdate = true;
        newArr.push(data);
      } else {
        newArr.push(element);
      }
    });

    userStockList.map((element) => {
      if (data?.stockId == element.stockId) {
        isUpdate = true;
        newUserArr.push({ ...element, currentPrice: data?.stockPrice || [] });
      } else {
        newUserArr.push(element);
      }
    });

    if (currentStockId && currentStockId === data?.stockId) {
      dispatch({
        type: actionTypes.SET_SPECIFIC_STOCK_PRICE_ARR,
        payload: {
          data: data.stockPrice,
          type: currentSingleStockObj.currencyName,
        },
      });
      dispatch(
        getPortfolioEngine({
          stockId: data.stockId,
        })
      );
    }

    if (stockPriceData?.length && stockPriceData[0]?.stockId == data?.stockId) {
      let newPriceData = [...stockPriceData];
      let filteredStock = data?.stockPrice?.filter(
        (element) => element?.currencyName == stockPriceData[0]?.currencyName
      );
      newPriceData.push({
        id: "",
        stockId: data?.stockId,
        currencyName: stockPriceData[0]?.currencyName,
        price: filteredStock[0]?.currentPrice,
        createdAt: filteredStock[0]?.updatedAt,
      });
      dispatch({
        type: actionTypes?.SET_STOCK_PRICE_DATA,
        payload: [...newPriceData],
      });
    }
    if (isUpdate) {
      dispatch({
        type: actionTypes.UPDATE_STOCK_REALTIME,
        payload: { stockList: [...newArr], userStockList: [...newUserArr] },
      });
    }
  };
};

export const updateStockPortfolioRealTime = (data) => {
  return async (dispatch, getState) => {
    const userStockList = getState()?.userReducer?.singleUserStock || [];

    if (
      userStockList &&
      userStockList?.filter((item) => {
        return item.stockId === data.stockId;
      })?.length > 0
    ) {
      // dispatch(getPortfolioEngine({
      //   limit:10,
      //   page:0
      // }));
    }
  };
};

export const getOrderBookRealTime = (data) => {
  return async (dispatch, getState) => {
    const order_book = getState()?.userReducer?.orderBook || [];
    if (
      data?.stockId == order_book.stockId &&
      data?.currencyName == order_book.currencyName
    ) {
      dispatch(setOrderBook({ orderBook: { ...data } }));
    }
  };
};

const setCurrId = (data) => {
  return {
    type: actionTypes.SET_CURRENT_ID,
    payload: data,
  };
};

export const storeCurrentStockid = (req) => {
  return async (dispatch, getState) => {
    dispatch(setCurrId({ data: req.stockId }));
    dispatch(
      setSingleStockPriceArr({ data: req.price, type: req.currencyType })
    );
  };
};

export const getStockCategory = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockCategoryLoading: true }));
    callApi("getStockCategory", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setStockCategory({
              stockCategory: data,
            })
          );

          dispatch(setUserLoading({ stockCategoryLoading: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ stockCategoryLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockCategoryLoading: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setCurrStockCat = (data) => {
  return {
    type: actionTypes.SET_CURRENT_STOCK_CATEGORY,
    payload: data,
  };
};

export const storeSelectedStockCategory = (req) => {
  return async (dispatch, getState) => {
    dispatch(setCurrStockCat({ data: req.data }));
  };
};

export const getStockTxnEngineUser = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ transactionList: true }));
    callApi("getStockTxnEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setTransactionData({
              stockTransactionData: data.data,
              // totalTransactionDataCount: data.count,
            })
          );
          dispatch(setUserLoading({ transactionList: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ transactionList: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ transactionList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setCogValueStock = (data) => {
  return {
    type: actionTypes.SET_COG_VALUE_STOCK,
    payload: data,
  };
};

export const getCogValueOfStock = (request) => {
  return async (dispatch, getState) => {
    callApi("getCogValueOfStock", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setCogValueStock({
              cogValueStock: data?.cogValue,
              buyValueStock: data?.buyValue,
              sellValueStock: data?.sellValue,
            })
          );
        } else {
          dispatch(notify(errorMessage, "error"));
        }
      })
      .catch((err) => {
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const cancelTradeStockEngine = (request) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      callApi("cancelTradeStockEngine", "POST", request)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          dispatch(setStatus({ error: err?.response?.data?.error || err }));
          dispatch(
            notify(
              err?.response?.data?.error || "Please try again by refresh..!",
              "error"
            )
          );
          reject(err);
        });
    });
  };
};

const setUserPreviousStocks = (data) => {
  return {
    type: actionTypes.SET_USER_PREVIOUS_STOCKS,
    payload: data,
  };
};

export const getPrevInvestment = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ userPreviousStock: true }));
    callApi("getPrevInvestment", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(
            setUserPreviousStocks({
              singleUserPreviousStock: data.data,
              singleUserPreviousStockCount: data.count,
              portfolioDataCurrentPagePreviousStock: request.page,
            })
          );
          dispatch(setUserLoading({ userPreviousStock: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ userPreviousStock: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ userPreviousStock: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setStockDetailsTemplate = (data) => {
  return {
    type: actionTypes.SET_STOCKS_DETAILS_TEMPLATE,
    payload: data,
  };
};

export const getStockTemplate = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ stockDetailsLoading: true }));
    callApi("getStockTemplate", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(setStockDetailsTemplate({ stockDetailsData: data }));
          dispatch(setUserLoading({ stockDetailsLoading: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          dispatch(setUserLoading({ stockDetailsLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ stockDetailsLoading: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setInvestmentDetail = (data) => {
  return {
    type: actionTypes.SET_INVESTMENT_DETAILS,
    payload: data,
  };
};

export const getInvestmentDetail = (request) => {
  return async (dispatch, getState) => {
    dispatch(setUserLoading({ investmentData: true }));
    callApi("getInvestmentDetail", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setInvestmentDetail({
              investmentData: data,
              stockId: request.stockId,
            })
          );
          dispatch(setUserLoading({ investmentData: false }));
        } else {
          console.log((errorMessage, "error"));
          dispatch(setUserLoading({ investmentData: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserLoading({ investmentData: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getStockTxnExportEngine = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ transactionList: true }));
    callApi("getStockTxnEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(
            setTransactionExportData({
              stockTransactionExportData: data.data,
              totalTransactionExportDataCount: data.count,
            })
          );
          // dispatch(setUserLoading({ transactionList: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ transactionList: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ transactionList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

export const getWalletTxnExportEngine = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ walletDataLoading: true }));
    callApi("getWalletTxnEngine", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(
            setWalletTransactionExportData({
              stockWalletExportTransactionData: data.data,
              totalWalletTransactionExportDataCount: data.count,
            })
          );
          // dispatch(setUserLoading({ walletDataLoading: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ walletDataLoading: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ walletDataLoading: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refreshing..!",
            "error"
          )
        );
      });
  };
};

export const isCryptopiaDataAvailable = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    // let storedToken = getWithExpiry("token");

    const reqBody = {};

    callApi("isCryptopiaData", "POST", reqBody)
      .then((res) => {
        const { isCryptopiaUser, status, successMessage, errorMessage, error } =
          res;
        // console.log({ isCryptopiaUser });
        dispatch(setIsCryptopiaDataAvailable(isCryptopiaUser));
        dispatch(
          setStatus({
            error: error,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        dispatch(notify(err?.message, "error"));
        dispatch(
          setStatus({
            error: err?.response?.data.error || err,
            errorMessage:
              err?.response?.data?.errorMessage ||
              "Please try again by refresh..!",
          })
        );
      });
  };
};

const setStockDoc = (data) => {
  return {
    type: actionTypes.SET_STOCK_DOC,
    payload: data,
  };
};

export const getStockDoc = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ transactionList: true }));
    callApi("getStockDoc", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;

        if (status) {
          dispatch(setStockDoc({ stockDoc: data }));
          // dispatch(setUserLoading({ transactionList: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ transactionList: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ transactionList: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};

const setOrderBookDetail = (data) => {
  return { type: actionTypes.SET_ORDER_BOOK_DETAIL, payload: data };
};
export const getStockOrderBookDetail = (request) => {
  return async (dispatch, getState) => {
    // dispatch(setUserLoading({ stockPrice: true }));
    callApi("getStockOrderBookDetail", "POST", request || [])
      .then((res) => {
        const { status, successMessage, errorMessage, error, data } = res;
        if (status) {
          dispatch(
            setOrderBookDetail({
              stockOrderBook: data,
            })
          );
          // dispatch(setUserLoading({ stockPrice: false }));
        } else {
          dispatch(notify(errorMessage, "error"));
          // dispatch(setUserLoading({ stockPrice: false }));
        }
      })
      .catch((err) => {
        // dispatch(setUserLoading({ stockPrice: false }));
        dispatch(setStatus({ error: err?.response?.data?.error || err }));
        dispatch(
          notify(
            err?.response?.data?.error || "Please try again by refresh..!",
            "error"
          )
        );
      });
  };
};
export const updateBalance = (data) => {
  return async (dispatch, getState) => {
    let state = getState().userReducer.accounts;
    state = state.map((account) => {
      if (account?.account_type == data?.account_type) {
        account.balance += data?.amount || 0;
      }
      return account.balance;
    });
    dispatch(setAccounts(state));
  };
};
