import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.setAttribute("id", id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	inputStyle: {
		//background: "#eeededd7",
		borderRadius: 8,
		border: 0,
		fontFamily: "Manrope",
	},
}));

export default function GoogleMaps(props) {
	const classes = useStyles();
	const { onValueChange, defaultAddress, error, helperText } = props;
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState("");
	const [options, setOptions] = React.useState([]);
	const loaded = React.useRef(false);

	React.useEffect(() => {
		// if(!loading && !profile){
		//   getProfile();
		// }
		if (defaultAddress) {
			{
				defaultAddress.length ? setValue(defaultAddress) : setValue(null);
			}
		}
	}, [defaultAddress]);

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
				document.querySelector("head"),
				"google-maps"
			);
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[]
	);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window?.google?.maps?.places?.AutocompleteService) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			// if(defaultAddress.length){
			//     setValue(defaultAddress);
			// }else{setValue(null);}

			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch, defaultAddress]);

	return (
		<Autocomplete
			id="google-map-demo"
			getOptionLabel={(option) =>
				typeof option === "string" ? option : option.description
			}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			freeSolo={true}
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
				onValueChange(newInputValue);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					required
					label="Address"
					className={classes.inputStyle}
					variant="outlined"
					fullWidth
					error={error}
					helperText={helperText}
				/>
			)}
			renderOption={(option) => {
				let matches = null;
				let parts = [];
				if (option) {
					if (option.structured_formatting) {
						if (option.structured_formatting.main_text_matched_substrings)
							matches = option.structured_formatting.main_text_matched_substrings;
					}
				}
				if (matches) {
					parts = parse(
						option.structured_formatting.main_text,
						matches.map((match) => [match.offset, match.offset + match.length])
					);
				}

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting
									? option.structured_formatting.secondary_text
									: ""}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
}
