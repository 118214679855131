import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { Box, ButtonBase, InputAdornment } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import Spinner from "../../components/common/Spinner/Spinner";
import { Typography } from "@material-ui/core";
import ThemeButton from "../common/button";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import FallBackImg from "../../asset/images/stocks/fallback.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingBottom: "5rem",

    [theme.breakpoints.down(950)]: {
      marginTop: "1rem",
      width: "100%",
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
    },
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    borderRadius: "16px",
    background: "#359Db6",
    marginTop: "2vh",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
    width: "100%",
    maxWidth: 220,
    "&:disabled": {
      background: "#9ACEDB",
      color: "#919191",
    },
  },
  titleStyle: {
    fontSize: 22,
    fontWeight: 600,
    minHeight: 40,
  },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: "2rem",
  },
  iframeContainer: {
    width: "100%",
    border: "none",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0",
  },
  filterSearchField: {
    width: "50%",
    maxWidth: "24rem",
    borderRadius: 50,
  },
  categoryFilterContainer: {
    position: "relative",
  },
  categoryFilterButton: {
    borderRadius: 15,
    border: "1px solid #646464",
    padding: "0.48rem",
    fontSize: "0.8rem",
  },
  categoryOptionsContainer: {
    position: "absolute",
    top: "3.2rem",
    padding: "0.5rem",
    left: 0,
    backgroundColor: "#2D2D2D",
    borderRadius: 12,
    zIndex: 3,
  },
  categoryOption: {
    display: "flex",
    justifyContent: "center",
  },
  categoryOptionBorder: {
    borderTop: "1px solid #656565",
  },
  selected: {
    backgroundColor: "#656565",
  },
}));

const StockExchangePage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    stockDetailsLoading,
    getStockTemplate,
    stockDetailsData,
    getAllStocksEngine,
    allStockEngineList,
    getStockDoc,
    stockDocument,
    getStockCategory,
    stockCategories,
    getStockOrderBookDetail,
    stockOrderBook,
  } = props;
  const [stockName, setStockName] = useState(useParams().stockName || null);
  const [stockId, setStockId] = useState(null);
  const [imageDocs, setImageDocs] = useState([]);

  const hasFetchedOrderBook = useRef(false);

  useEffect(() => {
    getStockTemplate({ stockName });
    getAllStocksEngine({ stockName });
  }, [stockName]);

  // Set stockId after allStockEngineList is available
  useEffect(() => {
    if (allStockEngineList && allStockEngineList.length > 0) {
      setStockId(allStockEngineList[0]?.stockId);
      console.log("All Stock Engine List:", allStockEngineList);
    }
  }, [allStockEngineList]);

  // Fetch data that requires stockId
  useEffect(() => {
    if (stockId) {
      getStockCategory({ stockId });
      getStockDoc({ stockId });

      if (!hasFetchedOrderBook.current) {
        getStockOrderBookDetail({ stockId });
        hasFetchedOrderBook.current = true;
      }
    }
  }, [stockId]);

  useEffect(() => {
    if (stockDocument) {
      const imageExtensions = ["png", "jpg", "jpeg", "gif"];
      const imgDcs = stockDocument?.filter((item) => {
        const extension = item.doc.split(".").pop();
        return imageExtensions.includes(extension.toLowerCase());
      });

      const filteredImage = imgDcs?.filter((item) => {
        return item.stock_id === stockId;
      });
      setImageDocs(filteredImage);
      console.log(filteredImage);
    }
  }, [stockDocument]);

  useEffect(() => {
    if (imageDocs?.length === 0) {
      setImageDocs(null);
    }
  }, [imageDocs]);

  const adjustIframeHeight = () => {
    const iframe = document.getElementById("stock-iframe");
    if (iframe && iframe.contentWindow && iframe.contentWindow.document.body) {
      iframe.style.height =
        iframe.contentWindow.document.body.scrollHeight + "px";
    }
  };
  const replacePlaceholders = (htmlString, stockId) => {
    const stockPrices = allStockEngineList?.[0]?.stockPrice || [];
    const tableHeight = "300px"; // You can adjust this height as necessary based on the layout
    const getDocumentSvg = (docName) => {
      if (!docName) {
        // Return a default SVG or an empty string
        return ""; // Or you can return a default SVG icon
      }
      const extension = docName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#9e9e9e">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
        case "doc":
        case "docx":
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#2962ff">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
        case "xls":
        case "xlsx":
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#4caf50">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M16 2H8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 15h-2v-2h-2v2H9v-3h2v-2H9v-3h2v2h2v-2h2v3h-2v2h2v3zm0-10H9V5h6v2z"/>
            </svg>`;
        default:
          return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" fill="#9e9e9e">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
            </svg>`;
      }
    };
    const stockPricesHtml = `
    <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
      <table style="width: 100%; color: white; border-spacing: 0;">
        <thead>
          <tr>
            <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Currency Name</th>
            <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Current Price</th>
          </tr>
        </thead>
        <tbody>
          ${stockPrices
            .map(
              (price) => `
            <tr>
              <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">${price.currencyName}</td>
              <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">€ ${price.currentPrice}</td>
            </tr>`
            )
            .join("")}
        </tbody>
      </table>
    </div>
  `;

    const stockCategoriesHtml = `
    <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
      <table style="width: 100%; color: white; border-spacing: 0;">
        <thead>
          <tr>
            <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Categories</th>
          </tr>
        </thead>
        <tbody>
          ${stockCategories
            ?.map(
              (category) => `
            <tr>
              <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">${category.name}</td>
            </tr>`
            )
            .join("")}
        </tbody>
      </table>
    </div>
  `;

    const orderBookHtml = `
    <div style="background-color: rgba(255, 255, 255, 0.1); padding: 20px; border-radius: 12px; text-align: center; box-sizing: border-box;">
      <table style="width: 100%; background-color: rgba(255, 255, 255, 0.1); color: white; border-spacing: 0; box-sizing: border-box;">
        <thead>
          <tr>
            <th style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Order Book</th>
          </tr>
        </thead>
        <tbody>
          <!-- Buy Orders Section -->
          <tr>
            <td style="font-weight: bold; padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Buy Orders</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Quantity:</td>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">${
              stockOrderBook?.stockOrderBook?.[0]?.buyQuantity || 0
            }</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Value:</td>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">€ ${
              stockOrderBook?.stockOrderBook?.[0]?.buyValue?.toFixed(2) || 0
            }</td>
          </tr>

          <!-- Sell Orders Section -->
          <tr>
            <td style="font-weight: bold; padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;" colspan="2">Sell Orders</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Quantity:</td>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">${
              stockOrderBook?.stockOrderBook?.[0]?.sellQuantity || 0
            }</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">Value:</td>
            <td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); box-sizing: border-box;">€ ${
              stockOrderBook?.stockOrderBook?.[0]?.sellValue?.toFixed(2) || 0
            }</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;

    const quantityInfoHtml = `
    <div style="background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; text-align: center; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);">
      <table style="width: 100%; color: white; border-spacing: 0;">
        <tbody>
          <tr><td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Total Quantity: ${
            allStockEngineList?.[0]?.totalQuantity || 0
          }</td></tr>
          <tr><td style="padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.2);">Assigned Quantity: ${
            allStockEngineList?.[0]?.assignedQuantity || 0
          }</td></tr>
        </tbody>
      </table>
    </div>
  `;

    const libraryHtml = `
  <div style="flex: 0 0 300px; background-color: rgba(255, 255, 255, 0.1); color: white; padding: 20px; border-radius: 12px; backdrop-filter: blur(10px); border: 1px solid rgba(255, 255, 255, 0.2); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); height: ${tableHeight}; overflow-y: auto;">
    <h3 style="font-size: 22px; font-weight: bold; margin-bottom: 10px;">Library</h3>
    <ul style="list-style-type: none; padding: 0; margin: 0; flex-grow: 1; white-space: nowrap;">
      ${stockDocument
        ?.filter((doc) => doc.stock_id === stockId) // Filter documents by stock_id
        ?.filter((doc) => {
          const extension = doc?.name?.split(".").pop().toLowerCase();
          return ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "svg",
            "webp",
            "mp4",
            "mov",
            "avi",
            "mkv",
            "mp3",
            "wav",
            "flac",
          ].includes(extension);
        })
        ?.map(
          (doc) => `
      <li style="display: flex; align-items: center; padding: 10px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.2); overflow-x: auto;">
        <a href="${
          process.env.REACT_APP_SPACE_LINK + "/" + doc.doc
        }" target="_blank" rel="noopener noreferrer">
          <div style="width: 30px; height: 30px; margin-right: 10px;">
            ${getDocumentSvg(doc.name)}
          </div>
        </a>
        <div>
          <span style="font-size: 16px; font-weight: bold;">${doc.name}</span>
        </div>
      </li>
      `
        )
        .join("")}
    </ul>
  </div>
`;

    // Replace placeholders with actual content
    return htmlString
      .replace("{{stockPrices}}", stockPricesHtml)
      .replace("{{stockCategories}}", stockCategoriesHtml)
      .replace("{{orderBook}}", orderBookHtml)
      .replace("{{quantityInfo}}", quantityInfoHtml)
      .replace("{{library}}", libraryHtml);
  };

  const adjustIframeHeight1 = () => {
    const iframe = document.getElementById("stock-iframe");

    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      const contentHeight = iframe.contentWindow.document.body.scrollHeight; // Get the height of the content
      console.log(`Iframe content height: ${contentHeight}px`); // Log the content height
      iframe.style.height = `${contentHeight}px`; // Set the iframe height to the content height
    }
  };

  return (
    <>
      {/* <Grid container xs={12} className={classes.container}>
        {!stockDetailsLoading ? (
          <>
            <Grid item xs={12}>
              <Box className={classes.filterContainer}>
                <ThemeButton
                  title="Back"
                  cursor="pointer"
                  width="100px"
                  id="backFromAddingBankAccount"
                  height="35px"
                  color="#fff"
                  border="2px solid #359DB6"
                  onClick={() => {
                    history.push("/stocks");
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {stockDetailsData && stockDetailsData?.length > 0 ? (
                <iframe
                  id="stock-iframe"
                  className={classes.iframeContainer}
                  srcDoc={replacePlaceholders(
                    stockDetailsData?.[0]?.template,
                    stockId
                  )}
                  title="Template Preview"
                  width="100%"
                  height="100%"
                  onLoad={adjustIframeHeight}
                  scrolling="no"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={FallBackImg}
                    alt="Fallback GIF"
                    height="250px"
                    width="300px"
                  />
                  <Typography
                    style={{ textAlign: "center", padding: "0 10rem" }}
                  >
                    Please wait, we apologize for the inconvenience. The
                    detailed business information for this stock is currently
                    unavailable. Please check back later for updates.
                  </Typography>
                </div>
              )}
            </Grid>
          </>
        ) : (
          <Spinner />
        )}
      </Grid> */}
      <Grid item xs={12}>
        {stockDetailsData && stockDetailsData?.length > 0 ? (
          <iframe
            id="stock-iframe"
            className={classes.iframeContainer}
            srcDoc={replacePlaceholders(
              stockDetailsData?.[0]?.template,
              stockId
            )}
            title="Template Preview"
            width="100%"
            onLoad={adjustIframeHeight1}
            scrolling="no"
            style={{
              border: "none", // No border for clean appearance
              padding: 0, // Remove extra padding
              margin: 0, // Remove extra margin
              display: "block", // Ensure it's a block-level element
              width: "75vw", // Span the entire width of the viewport
              maxWidth: "100%", // Ensure it doesn't exceed container width
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={FallBackImg}
              alt="Fallback GIF"
              height="250px"
              width="300px"
            />
            <Typography style={{ textAlign: "center", padding: "0 10rem" }}>
              Please wait, we apologize for the inconvenience. The detailed
              business information for this stock is currently unavailable.
              Please check back later for updates.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    stockDetailsData: userReducer.stockDetailsData,
    stockDetailsLoading: userReducer.loadings.stockDetailsLoading,
    allStockEngineList: userReducer.allStockEngineList,
    stockDocument: userReducer.stockDocument,
    stockCategories: userReducer.stockCategory,
    stockOrderBook: userReducer.stockOrderBook,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockTemplate: (data) => dispatch(actionCreator.getStockTemplate(data)),
    getAllStocksEngine: (data) =>
      dispatch(actionCreator.getAllStocksEngine(data)),
    getStockDoc: (data) => dispatch(actionCreator.getStockDoc(data)),
    getStockCategory: (data) => dispatch(actionCreator.getStockCategory(data)),
    getStockOrderBookDetail: (data) =>
      dispatch(actionCreator.getStockOrderBookDetail(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StockExchangePage);
