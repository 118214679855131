import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  loading: false,
  error: null,
  isLoggedIn: false,
  registered: false,
  checking: true,
  successMessage: "",
  errorMessage: "",
  regUser: null,
};

//# HERE IS THE REDUCER OR CASE'S
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return setLoading(state);
    case actionTypes.SET_STATUS:
      return setStatus(state, payload);
    case actionTypes.SET_REG_USER:
      return setRegUser(state, payload);
    case actionTypes.REGISTER:
      return register(state, payload);
    case actionTypes.LOGIN:
      return logIn(state, payload);
    case actionTypes.IS_LOG_IN:
      return isLogIn(state, payload);
    case actionTypes.LOGOUT:
      return logout(state, payload);

    default:
      return state;
  }
};

/*--- CASES ARE HERE DEFINED */
// UTILITY FUNCTION CALCULATE UTILIZATION

const setLoading = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const setStatus = (state, data) => {
  const { error, successMessage, errorMessage } = data;
  return updateObject(state, {
    loading: false,
    error: error || null,
    successMessage: successMessage || "",
    errorMessage: errorMessage || "",
  });
};

const setRegUser = (state, data) => {
  const { user, registered } = data;
  return updateObject(state, {
    regUser: user,
    registered: registered,
  });
};

const register = (state, data) => {
  const { registered } = data;
  return updateObject(state, {
    registered: registered,
  });
};

const logIn = (state, data) => {
  const { status } = data;
  return updateObject(state, {
    isLoggedIn: status,
    regUser: null,
  });
};

const isLogIn = (state, data) => {
  const { status } = data;
  return updateObject(state, {
    isLoggedIn: status,
    checking: false,
    regUser: null,
  });
};

const logout = (state, data) => {
  let { checking, registered, ...newState } = initialState;
  return updateObject(
    state,
    newState,
    //   {
    //   loading: false,
    //   error: false ,
    //   isLoggedIn : false,
    //   successMessage:"",
    //   errorMessage: "",
    //   regUser: null
    // }
  );
};

export default authReducer;
